import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
// import { useOutsideAlerter } from '../hooks/useOutsideAlerter'
import { dynamicId } from '../helpers/dynamicId'
const InnerSidebar = ({
  dataInnerMenu,
  activeComponent,
  tabs,
  handleData,
  dataTabs,
  handleTabData,
  isMenuOpen,
  onMobileclickMenuHandler,
  handleEventTrack
}) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 767px)'
  })
  const wrapperRef = useRef(null)

  // useOutsideAlerter(wrapperRef, onMobileclickMenuHandler)

  const [activeInnerNav, setActiveInnerNav] = useState(
    activeComponent.replace(/\s/g, '')
  )
  const [isMenu, setIsMenu] = useState(isMenuOpen)

  const [innerTabState, setInnerTabState] = useState({
    activeTitle: dataTabs,
    previousDataTabs: dataTabs
  })
  const optionRemove = () => {
    document.getElementsByTagName('body')[0].removeAttribute('class', '')
  }
  const onClickTab = item => {
    setIsMenu(false)
    optionRemove()
    onMobileclickMenuHandler()
    const stateName = item
    setInnerTabState(
      {
        activeTitle: stateName
      },
      () => {}
    )
    return handleTabData(item)
  }

  useEffect(() => {
    setIsMenu(isMenuOpen)
  }, [isMenuOpen])

  useState(() => {
    if (dataTabs !== innerTabState.previousDataTabs) {
      setInnerTabState(
        {
          activeTitle: dataTabs,
          previousDataTabs: dataTabs
        },
        () => {
          return handleTabData(innerTabState.activeTitle)
        }
      )
    }
  })

  const onClickInner = (event, menu) => {
    const stacks = [
      {
        found: 'account',
        replace: 'personal'
      },
      {
        found: 'company',
        replace: 'company'
      },
      {
        found: 'users',
        replace: 'users'
      },
      {
        found: 'teams',
        replace: 'teams'
      },
      {
        found: 'installation',
        replace: 'installation'
      },
      {
        found: 'api',
        replace: 'api'
      },
      {
        found: 'integration',
        replace: 'integration'
      },
      {
        found: 'lead qualification',
        replace: 'lead qualification'
      }
    ]

    let menuStr = ''
    const itm = stacks.find(item => {
      if (
        menu.innerTabs?.length &&
        item?.found ==
          menu.innerTabs?.[0]
            .toLowerCase()
            .split(' ')
            .join(' ')
      ) {
        menuStr =
          '/settings/' +
          menu.mainSidebar
            .toLowerCase()
            .split(' ')
            .join('_')

        return true
      } else {
        return false
      }
    })
    let menuString = ''
    if (itm) {
      menuString = menuStr
    } else {
      menuString = menu?.innerTabs?.length
        ? '/settings/' +
          menu?.mainSidebar
            .toLowerCase()
            .split(' ')
            .join('_') +
          '/' +
          menu?.innerTabs[0]
            .toLowerCase()
            .split(' ')
            .join('_')
        : '/settings/' +
          menu?.mainSidebar
            .toLowerCase()
            .split(' ')
            .join('_')
    }

    window.history.pushState('object or string', 'Title', menuString)
    const stateName = event.currentTarget.firstChild.getAttribute('id')
    const initialTab = menu.innerTabs[0]
    document.title = menu.mainSidebar + ' - ' + menu.innerTabs[0]
    handleEventTrack(menuString)
    setActiveInnerNav(stateName)
    handleData(stateName, initialTab, isMobile)
  }

  const renderMenu = (category) => {
    return dataInnerMenu
      .filter(menu => menu.category === category)
      .map(menu => (
        <div
          key={dynamicId()}
          onClick={e => onClickInner(e, menu)}
          className={activeInnerNav === `${menu.mainSidebar.replace(/\s/g, '')}` ? 'innersidebar-subitem active' : 'innersidebar-subitem'}
        >
          <span id={`${menu.mainSidebar.replace(/\s/g, '')}`} key={dynamicId()}>
            {menu.mainSidebar}
            <div className="innnerTab_wrapper">
              {menu.innerTabs.map(tab => (
                <div key={dynamicId()} className="innertab-item">
                  <div
                    className={`innser_tab_class ${innerTabState.activeTitle === tab ? 'active' : null}`}
                    onClick={() => onClickTab(tab)}
                  >
                    {tab}
                  </div>
                </div>
              ))}
            </div>
          </span>
        </div>
      ));
  };

  return (
    <div
      ref={wrapperRef}
      className={`innersidebar-container ${isMenu ? 'open' : null}`}
      key={dynamicId()}
    >
      <div className="innersidebar-wrapper">
   
        <h5 className='p-2 mt-0' style={{ fontSize:'16px',backgroundColor:'#186bcc1a'}} >Workspace</h5>
        {renderMenu('Workspace')}
        <h5 className='p-2 mt-0' style={{ fontSize: '16px', backgroundColor: '#186bcc1a' }}>Your Account</h5>
        {renderMenu('Your Account')}
      </div>
    </div>
  )
}

export default InnerSidebar
